import styled from "styled-components";

export const BadgeContainer = styled.div`
	${(props) =>
		props.inline
			? `
        display: inline-block;
    `
			: `
        display: flex;
        align-items: center;
    `};
	width: fit-content;
	padding: ${({ bgColor }) => (bgColor ? "0px 8px" : "0px")};
	border-radius: 32px;
	text-align: center;
	background-color: ${(props) => props.bgColor};
	white-space: nowrap;
`;
