import React from "react";
import PropTypes from "prop-types";
import { BadgeContainer } from "./style";
import { Text } from "@website-builder/ui/shared/elements";

const Badge = ({
	text,
	textColor,
	textVariant,
	bgColor,
	inline,
	children,
	...restProps
}) => {
	const content = children || text;
	return (
		<BadgeContainer bgColor={bgColor} inline={inline} {...restProps}>
			{!React.isValidElement(content) ? (
				<Text variant={textVariant} style={{ color: textColor }}>
					{content}
				</Text>
			) : (
				content
			)}
		</BadgeContainer>
	);
};

Badge.defaultProps = {
	text: "",
	textColor: "#000",
	textVariant: "label_M",
	bgColor: "transparent",
	inline: false,
};

Badge.propTypes = {
	text: PropTypes.string,
	textColor: PropTypes.string,
	textVariant: PropTypes.string,
	bgColor: PropTypes.string,
	inline: PropTypes.bool,
};

export default Badge;
